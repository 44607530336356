// import React from 'react';
// import '../styles/Loading.css';

// const LoadingSpinner = () => {
//   return (
//     <div className="spinner-container">
//       <div className="loading-spinner"></div>
//       <p>Generating your content, please wait...</p>
//     </div>
//   );
// };

// export default LoadingSpinner;

import React, { useState, useEffect } from 'react';

const LoadingScreen = ({ apiCallDuration }) => {
  const [timeLeft, setTimeLeft] = useState(apiCallDuration); // Initialize the countdown with the provided API call duration
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1); // Decrease time left by 1 second
      }, 1000);

      return () => clearInterval(timer); // Cleanup the timer on component unmount
    } else {
      setLoadingComplete(true); // Once countdown reaches 0, set loading as complete
    }
  }, [timeLeft]);

  // Calculate the percentage of time left
  const progressPercentage = ((apiCallDuration - timeLeft) / apiCallDuration) * 100;

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      {!loadingComplete ? (
        <div className="text-center">
          <div className="mb-4 text-xl font-semibold text-gray-700">
            Your request is being processed...
          </div>
          <div className="text-lg text-blue-600">
            Estimated time left: {timeLeft}s
          </div>

          {/* Progress Bar */}
          <div className="w-full h-4 bg-gray-300 rounded-full mt-4 overflow-hidden">
            <div
              className="h-full bg-blue"
              style={{ width: `${progressPercentage}%`, transition: 'width 1s linear' }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <div className="text-center text-lg text-green-500">
            Processing complete!<br />Rendering the Results
          </div>
          {/* Final Progress Bar at 100% */}
          <div className="w-full min-w-[340px] h-4 bg-gray-300 rounded-full mt-4 overflow-hidden">
            <div className="h-full bg-blue" style={{ width: `100%` }}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoadingScreen;

