import LocalBrandForm from '../compements/form/BrandForm.js';
import FormBG from '../media/Questions-bg.jpg';
import LocalHomepageHeroHeader from '../compements/HomepageHeroHeader.js';

export default function HomeApp() {
    return (
    <>
        <div className='bg-cover bg-no-repeat bg-top' style={{backgroundImage: `url(${FormBG})`}}>
            <LocalHomepageHeroHeader />
            <div className = {`max-w-[1038px] mx-auto p-4 mt-[-140px] relative z-10`}>
                <LocalBrandForm />
            </div>
        </div>
    </>
    )
}