import React from 'react'
import Tiles from '../compements/Tiles'
import '../styles/Results.css'
import { useNavigate } from 'react-router-dom';
function Content() {
  const navigate = useNavigate();
  return (
    <div className = {`max-w-[1200px] mx-auto p-8 flex flex-col items-center`}>
      <h1 className='text-center text-3xl font-bold my-10 uppercase text-blue'>Select the Content Piece</h1>
      <Tiles/>
      <button onClick={() => navigate(-1)} className={`h-full w-[80%] mt-10 px-4 py-2 bg-[#369FFF] text-white rounded-full hover:bg-opacity-90 font-bold `}>{`< Change Brand Data`}</button>
    </div>
  )
}

export default Content
