import React from 'react'
import DskBg from '../media/HeroHeaderBg.png';
import DskHeroImg from '../media/RudyHeroHeader.png'
import DskHeroImgText from '../media/100PiecesOfContent.png';

function HomepageHeroHeader() {
    return(
      <div className='bg-bottom bg-cover py-10' style={{backgroundImage: `url(${DskBg})`}}>
        <div className='flex items-end max-w-[1038px] mx-auto p-4 flex-wrap'>
            <div className='md:w-[50%] w-[100%] flex justify-center'>
                <img src={DskHeroImg} className='h-auto max-w-full'/>
            </div>
            <div className='md:w-[50%] pb-16 w-[100%] flex justify-center mt-[-120px] ml-[-20px] md:ml-0'>
                <img src={DskHeroImgText} className='h-auto max-w-full'/>
            </div>
        </div>
      </div>
    )
}

export default HomepageHeroHeader
