import React from 'react';
import Tile from './Tile';
import CarouselImg from '../media/Tiles/CarouselImg.svg';
import CaseStudyImg from '../media/Tiles/CaseStudyImg.svg';
import FactsImg from '../media/Tiles/FactsImg.svg';
import HeadlinesIMG from '../media/Tiles/HeadlinesIMG.svg';
import MythBusterImg from '../media/Tiles/MythBusterImg.svg';
import PromotionalImg from '../media/Tiles/PromotionalImg.svg';
import QuotesImg from '../media/Tiles/QuotesImg.svg';
import Stats from '../media/Tiles/Stats.svg';
import VideoImg from '../media/Tiles/VideoImg.svg';
function Tiles() {
    const getStoredData = () => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
          return JSON.parse(savedData); // Parse the string back into an object
        }
        return null; // Return null if no data is found
      };
      const props = getStoredData();
  const promptPostStructure = " Your response should be strictly in HTML to be used inside the body tag, and don't use any classes, use <ol style='list-style: auto;'> list to separate all the of them, use heading tag h2 for each content heading and paragraph tag p for actual content in the li tag. Use no images please and don't include an HTML starter or mention anything about how the content was generated."
      // console.log(props)
    const content = {
        tile1: {
            color:"#369FFF",
            heading: "Video Scripts",
            content: "Create engaging video scripts for your content.",
            button: "Write My Script",
            prompt: `Create 5 unique video scripts tailored for ${props.brandNameNiche}. Each video should target ${props.targetAudience}, reflect ${props.keyBrandValuesUSP}, and maintain the brand’s tone (${props.toneVoiceContent}). Video content should be highly engaging, insightful, and relevant to my audience’s needs:
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.
            Content is created one category at a time, with the GPT generating video scripts in a 400-500 word transcript style for deeper engagement, including engaging language, a clear call to action, and a short accompanying social media post.`,
            // prompt: `Create 1  0 unique video scripts tailored for ${props.brandNameNiche}. Each video should target ${props.targetAudience}, reflect ${props.keyBrandValuesUSP}, and maintain the brand’s tone (${props.toneVoiceContent}). Video content should be highly engaging, insightful, and relevant to your audience’s needs. Structure the scripts as follows:
            //           1. Intro Video: Introduce your brand, the niche it operates in (${props.brandNameNiche}), and your target audience (${props.targetAudience}). Explain how your brand solves their pain points while showcasing your key values (${props.keyBrandValuesUSP}).
            //           2. Educational Video: Dive deep into one of ${props.mainTeachingTopics}. Explain key concepts in a way that directly addresses ${props.targetAudience}'s challenges and frustrations. Reference past content that worked well (drawing from ${props.topPerformingPosts}) to demonstrate your expertise.
            //           3. Product/Service Showcase Video: Highlight a key product or service, explaining how it helps solve the audience's pain points. Use success stories or social proof to strengthen your case. Keep the tone engaging and reflect the voice of your brand.
            //           4. Behind-the-Scenes Video: Take the audience behind the curtain of your brand, showing how products are made, what inspires the brand, or any personal insights that align with ${props.keyBrandValuesUSP}.
            //           5. Testimonial Video: Showcase a customer story that highlights how your brand helped them overcome key challenges. Personalize it by tying it back to the frustrations (${props.targetAudience}) face.
            //           6. Tips/How-To Video: Offer practical advice or steps related to ${props.mainTeachingTopics}. Provide clear, actionable steps while keeping the script short, following the desired video length (${props.contentLength}).
            //           7. Seasonal/Event-Based Video: Create a video around an upcoming event or holiday, tying in how your brand stays relevant during these times.
            //           8. FAQ Video: Address common questions or misconceptions about your brand, products, or niche. Use this as an opportunity to directly engage with the audience and encourage interaction.
            //           9. Storytelling Video: Tell a story about your brand, starting with its inception, why it was created, and how it aligns with ${props.keyBrandValuesUSP}. This personal approach helps humanize the brand.
            //           10. Re-engagement Video: Script a video aimed at re-engaging an inactive audience. Remind them why they subscribed to your content initially and provide updates or offers that encourage them to reconnect.
            //           Each video script should reflect the tone (${props.toneVoiceContent}) and include a strong call to action.`+promptPostStructure,
            // prompt1: `Intro Video: Introduce your brand, the niche it operates in (${props.brandNameNiche}), and your target audience (${props.targetAudience}). Explain how your brand solves their pain points while showcasing your key values (${props.keyBrandValuesUSP}).`,
            // prompt2: `Educational Video: Dive deep into one of ${props.mainTeachingTopics}. Explain key concepts in a way that directly addresses ${props.targetAudience}'s challenges and frustrations. Reference past content that worked well (drawing from ${props.topPerformingPosts}) to demonstrate your expertise.`,
            // prompt3: `Product/Service Showcase Video: Highlight a key product or service, explaining how it helps solve the audience's pain points. Use success stories or social proof to strengthen your case. Keep the tone engaging and reflect the voice of your brand.`,
            // prompt4: `Behind-the-Scenes Video: Take the audience behind the curtain of your brand, showing how products are made, what inspires the brand, or any personal insights that align with ${props.keyBrandValuesUSP}.`,
            // prompt5: `Testimonial Video: Showcase a customer story that highlights how your brand helped them overcome key challenges. Personalize it by tying it back to the frustrations (${props.targetAudience}) face.`,
            // prompt6: `Tips/How-To Video: Offer practical advice or steps related to ${props.mainTeachingTopics}. Provide clear, actionable steps while keeping the script short, following the desired video length (${props.contentLength}).`,
            // prompt7: `Seasonal/Event-Based Video: Create a video around an upcoming event or holiday, tying in how your brand stays relevant during these times.`,
            // prompt8: `FAQ Video: Address common questions or misconceptions about your brand, products, or niche. Use this as an opportunity to directly engage with the audience and encourage interaction.`,
            // prompt9: `Storytelling Video: Tell a story about your brand, starting with its inception, why it was created, and how it aligns with ${props.keyBrandValuesUSP}. This personal approach helps humanize the brand.`,
            // prompt10: 'Re-engagement Video: Script a video aimed at re-engaging an inactive audience. Remind them why they subscribed to your content initially and provide updates or offers that encourage them to reconnect.',
            // prompt11: `Quick Brand Overview: Summarize your brand, why it was created, and its mission in a fast-paced, dynamic format to grab attention.`,
            // prompt12: `Explainer Animation: Use animated characters or visuals to explain one of ${props.mainTeachingTopics} in a simplified, engaging way.`,
            // prompt13: `Customer Journey Video: Showcase a real customer’s journey with your product/service, from the moment they discovered it to the benefits they’ve enjoyed.`,
            // prompt14: `Tips Compilation: Share 5 quick tips on how your audience can benefit from ${props.mainTeachingTopics}, packaged in a rapid-cut style for short attention spans.`,
            // prompt15: `Collaborative Video: Partner with an influencer in your niche to create a co-branded video discussing how they used your product/service.`,
            // prompt16: `Unboxing Video: Show a live unboxing of one of your products, highlighting its key features while emphasizing your brand’s USP.`,
            // prompt17: `Challenge Video: Create a challenge that encourages the audience to use your product or follow your service steps, sparking engagement and shares.`,
            // prompt18: `Step-by-Step Demo: Provide a detailed step-by-step demo on how to best use one of your products to solve a common problem for your audience.`,
            // prompt19: `Testimonial Montage: Compile clips of different customer testimonials to create a powerful narrative about your brand’s impact.`,
            // prompt20: `Reaction Video: Show audience reactions or influencers using your product in real-time, highlighting their positive feedback.`,
            // prompt21: `First Impressions Video: Record customers' first impressions of your product, capturing the raw, authentic reactions that highlight your brand's strengths.`,
            // prompt22: `Comparison Video: Compare your product or service with a common alternative, showing why yours is the superior choice for ${props.targetAudience}.`,
            // prompt23: `Transformation Story: Follow a customer through their journey of transformation using your product, showing before-and-after results.`,
            // prompt24: `Educational Series: Break down one complex topic related to ${props.mainTeachingTopics} into a mini-series of bite-sized videos for deeper engagement.`,
            // prompt25: `Interactive Q&A: Host a live Q&A video where your audience submits questions about your brand or products, and you answer them in real-time.`,
            // prompt26: `Event Recap: Create a video summarizing a recent event (virtual or physical) that your brand was part of, emphasizing your contributions.`,
            // prompt27: `Myth-Busting Video: Address common misconceptions about your industry or niche, debunking them in an entertaining way with data and examples.`,
            // prompt28: `Fan-Made Compilation: Compile user-generated content into a video where fans showcase how they use your products in creative ways.`,
            // prompt29: `How We Work: Take your audience behind the scenes, showing your team in action and how they create or deliver your products/services.`,
            // prompt30: `Success Formula Breakdown: Present your brand’s formula for success in ${props.mainTeachingTopics}, explaining each component in a visually appealing format.`,
            prompts: [
            `Intro Video: Introduce your brand, the niche it operates in ('${props.brandNameNiche}'), and your target audience ('${props.targetAudience}'). Explain how your brand solves their pain points while showcasing your key values ('${props.keyBrandValuesUSP}').`,
            `Educational Video: Dive deep into one of '${props.mainTeachingTopics}'. Explain key concepts in a way that directly addresses '${props.targetAudience}''s challenges and frustrations. Reference past content that worked well (drawing from '${props.topPerformingPosts}') to demonstrate your expertise.`,
            `Product/Service Showcase Video: Highlight a key product or service, explaining how it helps solve the audience's pain points. Use success stories or social proof to strengthen your case. Keep the tone engaging and reflect the voice of your brand.`,
            `Behind-the-Scenes Video: Take the audience behind the curtain of your brand, showing how products are made, what inspires the brand, or any personal insights that align with '${props.keyBrandValuesUSP}'.`,
            `Testimonial Video: Showcase a customer story that highlights how your brand helped them overcome key challenges. Personalize it by tying it back to the frustrations ('${props.targetAudience}') face.`,
            `Tips/How-To Video: Offer practical advice or steps related to '${props.mainTeachingTopics}'. Provide clear, actionable steps while keeping the script short, following the desired video length ('${props.contentLength}').`,
            `Seasonal/Event-Based Video: Create a video around an upcoming event or holiday, tying in how your brand stays relevant during these times.`,
            `FAQ Video: Address common questions or misconceptions about your brand, products, or niche. Use this as an opportunity to directly engage with the audience and encourage interaction.`,
            `Storytelling Video: Tell a story about your brand, starting with its inception, why it was created, and how it aligns with '${props.keyBrandValuesUSP}'. This personal approach helps humanize the brand.`,
             `Re-engagement Video: Script a video aimed at re-engaging an inactive audience. Remind them why they subscribed to your content initially and provide updates or offers that encourage them to reconnect.`,
             `Quick Brand Overview: Summarize your brand, why it was created, and its mission in a fast-paced, dynamic format to grab attention.`,
             `Explainer Animation: Use animated characters or visuals to explain one of '${props.mainTeachingTopics}' in a simplified, engaging way.`,
             `Customer Journey Video: Showcase a real customer’s journey with your product/service, from the moment they discovered it to the benefits they’ve enjoyed.`,
             `Tips Compilation: Share 5 quick tips on how your audience can benefit from '${props.mainTeachingTopics}', packaged in a rapid-cut style for short attention spans.`,
             `Collaborative Video: Partner with an influencer in your niche to create a co-branded video discussing how they used your product/service.`,
             `Unboxing Video: Show a live unboxing of one of your products, highlighting its key features while emphasizing your brand’s USP: '${props.keyBrandValuesUSP}'.`,
             `Challenge Video: Create a challenge that encourages the audience to use your product or follow your service steps, sparking engagement and shares.`,
             `Step-by-Step Demo: Provide a detailed step-by-step demo on how to best use one of your products to solve a common problem for your audience.`,
             `Testimonial Montage: Compile clips of different customer testimonials to create a powerful narrative about your brand’s impact.`,
             `Reaction Video: Show audience reactions or influencers using your product in real-time, highlighting their positive feedback.`,
             `First Impressions Video: Record customers' first impressions of your product, capturing the raw, authentic reactions that highlight your brand's strengths.`,
             `Comparison Video: Compare your product or service with a common alternative, showing why yours is the superior choice for '${props.targetAudience}'.`,
             `Transformation Story: Follow a customer through their journey of transformation using your product, showing before-and-after results.`,
             `Educational Series: Break down one complex topic related to '${props.mainTeachingTopics}' into a mini-series of bite-sized videos for deeper engagement.`,
             `Interactive Q&A: Host a live Q&A video where your audience submits questions about your brand or products, and you answer them in real-time.`,
             `Event Recap: Create a video summarizing a recent event (virtual or physical) that your brand was part of, emphasizing your contributions.`,
             `Myth-Busting Video: Address common misconceptions about your industry or niche, debunking them in an entertaining way with data and examples.`,
             `Fan-Made Compilation: Compile user-generated content into a video where fans showcase how they use your products in creative ways.`,
             `How We Work: Take your audience behind the scenes, showing your team in action and how they create or deliver your products/services.`,
             `Success Formula Breakdown: Present your brand’s formula for success in '${props.mainTeachingTopics}', explaining each component in a visually appealing format.`
            ],
            image: `${VideoImg}`,
        },
        tile2: {
            color:"#FF993A",
            heading: "Carousel Posts",
            content: "Design captivating carousel posts for social media.",
            button: "Create My Post",
            prompt: `Create 5 carousel post ideas for '${props.brandNameNiche}', focusing on '${props.targetAudience}' and posting on '${props.socialMediaPlatforms}'. The content should align with '${props.keyBrandValuesUSP}' and follow the tone '${props.toneVoiceContent}'. Use inspiration from '${props.topPerformingPosts}' where applicable. you should create 3 slides for each post and differenciate them by slide 1 slide 2 and slide 3
            **Carousel Posts**: Generated individually, with each containing 3 unique image captions and one cohesive post text.
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            // prompt: `Create 10 carousel post ideas for '${props.brandNameNiche}', focusing on '${props.targetAudience}' and posting on '${props.socialMediaPlatforms}'. The content should align with '${props.keyBrandValuesUSP}' and follow the tone '${props.toneVoiceContent}'. Use inspiration from '${props.topPerformingPosts}' where applicable.
            //         1. How-To Guide: Teach one of '${props.mainTeachingTopics}', breaking it down into easy-to-follow steps in each slide.
            //         2. Product Showcase: Introduce one of your key products or services, highlighting its benefits for '${props.targetAudience}' across several slides.
            //         3. Before & After: Show the impact of your product/service with a before-and-after comparison relevant to your audience's pain points.
            //         4. Customer Testimonial: Share a testimonial from a satisfied customer, with each slide focusing on different parts of their story.
            //         5. Success Story: Tell a success story where each slide walks through the journey of how your brand helped someone from your '${props.targetAudience}'.
            //         6. Tips & Tricks: Share quick actionable tips related to '${props.mainTeachingTopics}', with one tip per slide.
            //         7. FAQ Carousel: Answer common questions about your product/service or industry, with one question per slide.
            //         8. Seasonal Special: Create a seasonal offer related to an upcoming holiday or event, with a call to action on the last slide.
            //         9. Behind-the-Scenes: Showcase how one of your products or services is created or the inner workings of your team.
            //         10. Comparison Carousel: Compare your product or service to a common alternative, showing why yours is the better choice for '${props.targetAudience}'.`+promptPostStructure,
            prompts: [
            // `How-To Guide: Teach one of '${props.mainTeachingTopics}', breaking it down into easy-to-follow steps in each slide.`,
            // `Product Showcase: Introduce one of your key products or services, highlighting its benefits for '${props.targetAudience}' across several slides.`,
            // `Before & After: Show the impact of your product/service with a before-and-after comparison relevant to your audience's pain points.`,
            // `Customer Testimonial: Share a testimonial from a satisfied customer, with each slide focusing on different parts of their story.`,
            // `Success Story: Tell a success story where each slide walks through the journey of how your brand helped someone from your '${props.targetAudience}'.`,
            // `Tips & Tricks: Share quick actionable tips related to '${props.mainTeachingTopics}', with one tip per slide.`,
            // `FAQ Carousel: Answer common questions about your product/service or industry, with one question per slide.`,
            // `Seasonal Special: Create a seasonal offer related to an upcoming holiday or event, with a call to action on the last slide.`,
            // `Behind-the-Scenes: Showcase how one of your products or services is created or the inner workings of your team.`,
            // `Comparison Carousel: Compare your product or service to a common alternative, showing why yours is the better choice for '${props.targetAudience}'.`,
            `Slide 1: Meet '${props.brandNameNiche}', Slide 2: Here’s why '${props.targetAudience}' trust us, Slide 3: Learn more about our '${props.keyBrandValuesUSP}'!`,
            `Slide 1: The problem '${props.targetAudience}' faces, Slide 2: How '${props.brandNameNiche}' solves it, Slide 3: Here’s what makes us unique.`,
            `Slide 1: The top benefits of choosing '${props.brandNameNiche}', Slide 2: Real results from our happy '${props.targetAudience}', Slide 3: Discover our '${props.keyBrandValuesUSP}'.`,
            `Slide 1: Introducing '${props.brandNameNiche}', Slide 2: How we help '${props.targetAudience}', Slide 3: Our secret weapon: '${props.keyBrandValuesUSP}'.`,
            `Slide 1: The journey of '${props.targetAudience}', Slide 2: Why '${props.brandNameNiche}' is the perfect fit, Slide 3: What our '${props.keyBrandValuesUSP}' offers.`,
            `Slide 1: Struggling with '${props.targetAudience}''s frustrations? Slide 2: Here’s how '${props.brandNameNiche}' can help, Slide 3: Why our '${props.keyBrandValuesUSP}' matters.`,
            `Slide 1: Why choose '${props.brandNameNiche}'? Slide 2: Our success stories, Slide 3: Learn more about our '${props.keyBrandValuesUSP}'.`,
            `Slide 1: How '${props.targetAudience}' can benefit from '${props.brandNameNiche}', Slide 2: What makes us stand out, Slide 3: The value of our '${props.keyBrandValuesUSP}'.`,
            `Slide 1: Common challenges faced by '${props.targetAudience}', Slide 2: How '${props.brandNameNiche}' addresses these, Slide 3: Our commitment to '${props.keyBrandValuesUSP}'.`,
            `Slide 1: What makes '${props.brandNameNiche}' different, Slide 2: The results '${props.targetAudience}' see, Slide 3: Why our '${props.keyBrandValuesUSP}' is key.`,
            `Slide 1: Why '${props.targetAudience}' should choose '${props.brandNameNiche}', Slide 2: What sets us apart, Slide 3: Real stories, real results.`,
            `Slide 1: Challenges '${props.targetAudience}' face, Slide 2: Our solution: '${props.brandNameNiche}', Slide 3: How '${props.keyBrandValuesUSP}' solves it.`,
            `Slide 1: '${props.brandNameNiche}' at a glance, Slide 2: The power of '${props.keyBrandValuesUSP}', Slide 3: Why '${props.targetAudience}' should choose us.`,
            `Slide 1: How '${props.brandNameNiche}' works, Slide 2: Success stories from '${props.targetAudience}', Slide 3: Our '${props.keyBrandValuesUSP}' in action.`,
            `Slide 1: The key to success with '${props.brandNameNiche}', Slide 2: What '${props.targetAudience}' love about us, Slide 3: The magic of '${props.keyBrandValuesUSP}'.`,
            `Slide 1: Meet '${props.brandNameNiche}', Slide 2: Our impact on '${props.targetAudience}', Slide 3: How '${props.keyBrandValuesUSP}' drives results.`,
            `Slide 1: Why '${props.targetAudience}' choose '${props.brandNameNiche}', Slide 2: What makes our '${props.keyBrandValuesUSP}' so important, Slide 3: Real customer transformations.`,
            `Slide 1: Introducing '${props.brandNameNiche}', Slide 2: How we solve '${props.targetAudience}''s challenges, Slide 3: What makes '${props.keyBrandValuesUSP}' special.`,
            `Slide 1: What '${props.brandNameNiche}' is all about, Slide 2: Our commitment to '${props.targetAudience}', Slide 3: Learn about our '${props.keyBrandValuesUSP}'.`,
            `Slide 1: The value of '${props.brandNameNiche}', Slide 2: How we help '${props.targetAudience}', Slide 3: Our unique '${props.keyBrandValuesUSP}' that makes a difference.`,
            ],
            image: `${CarouselImg}`,
        },
        tile3: {
            color:"#8AC53E",
            heading: "Stats/Figure Posts",
            content: "Share compelling stats and figures with ease.",
            button: "Generate My Post",
            prompt: `Create 5 posts centered around key statistics and figures for ${props.brandNameNiche}, tailored to ${props.targetAudience} on ${props.socialMediaPlatforms}. Ensure the stats are impactful and support ${props.keyBrandValuesUSP}, following the tone ${props.toneVoiceContent}.
            **Authority Stats/Facts**: Provided in sets of 5 until the total of 12 is met, with each image caption stating the statistic or fact for enhanced credibility.
            Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
                    // 1. Industry Growth Stats: Highlight an impressive stat about growth in your industry that supports why ${props.targetAudience} should invest in your services.
                    // 2. Customer Success Numbers: Showcase how many customers or clients you’ve helped and how that translates into meaningful results.
                    // 3. Before-and-After Figures: Use stats to demonstrate the before-and-after impact your product or service has on users.
                    // 4. Cost Savings Stat: Share a statistic about how much time or money your product/service saves for ${props.targetAudience}.
                    // 5. Time-Saving Data: Highlight how your solution cuts down time on common tasks for ${props.targetAudience}.
                    // 6. Success Rate: Display a compelling success rate or satisfaction percentage related to your services.
                    // 7. Market Insights: Share a relevant market or industry insight that positions ${props.brandNameNiche} as a thought leader.
                    // 8. Customer Retention Stat: Show off a customer retention rate or repeat customer percentage to build trust.
                    // 9. Performance Metrics: Display internal performance metrics that show how effective your offerings are.
                    // 10. Survey Results: Share survey or poll results that relate to your audience’s pain points and how your brand addresses them.`+promptPostStructure,
            prompts:[
              // `Did you know? 85% of '${props.targetAudience}' report higher satisfaction after switching to '${props.brandNameNiche}'. Our '${props.keyBrandValuesUSP}' makes the difference!`,
              // `Over 70% of '${props.targetAudience}' see results within the first month of using '${props.brandNameNiche}'. Ready to be part of the success story?`,
              // `Our success rate speaks for itself! 9 out of 10 '${props.targetAudience}' recommend '${props.brandNameNiche}' because of our '${props.keyBrandValuesUSP}'.`,
              // `85% of '${props.targetAudience}' say they’ve never been more satisfied after choosing '${props.brandNameNiche}'. Find out why!`,
              // `The numbers don’t lie: '${props.brandNameNiche}' has helped over 50,000 '${props.targetAudience}' achieve their goals with our unique '${props.keyBrandValuesUSP}'.`,
              // `Here’s a stat for you: '${props.brandNameNiche}' has a 90% customer retention rate among '${props.targetAudience}'. Want to know why? It’s all about our '${props.keyBrandValuesUSP}'.`,
              // `94% of '${props.targetAudience}' report that '${props.brandNameNiche}' exceeded their expectations with our '${props.keyBrandValuesUSP}'. Ready to join them?`,
              // `More than 80% of '${props.targetAudience}' say that '${props.brandNameNiche}' is their go-to solution because of our '${props.keyBrandValuesUSP}'.`,
              // `Fact: '${props.brandNameNiche}' has reduced frustrations for 70% of '${props.targetAudience}'. Could our '${props.keyBrandValuesUSP}' help you too?`,
              // `Our unique '${props.keyBrandValuesUSP}' has led to a 75% increase in satisfaction among '${props.targetAudience}'. Find out how today.`,
              // `80% of '${props.targetAudience}' say that '${props.brandNameNiche}' solved their biggest pain point. Want to know how? It’s our '${props.keyBrandValuesUSP}'.`,
              // `95% of '${props.targetAudience}' report noticeable results within the first 30 days of using '${props.brandNameNiche}'. Discover the power of our '${props.keyBrandValuesUSP}'.`,
              // `Looking for results? 88% of '${props.targetAudience}' credit '${props.brandNameNiche}' for their improved performance. It’s all thanks to our '${props.keyBrandValuesUSP}'.`,
              // `Fact: '${props.brandNameNiche}' has a customer satisfaction rate of 93% among '${props.targetAudience}'. Why? Because of our unique '${props.keyBrandValuesUSP}'.`,
              // `76% of '${props.targetAudience}' say that '${props.brandNameNiche}' helped them overcome their biggest challenges. It’s all in our '${props.keyBrandValuesUSP}'.`,
              // `98% of '${props.targetAudience}' would recommend '${props.brandNameNiche}' to others. Curious what makes us different? Our '${props.keyBrandValuesUSP}'.`,
              // `Join the 87% of '${props.targetAudience}' who report success after switching to '${props.brandNameNiche}'. Our '${props.keyBrandValuesUSP}' works!`,
              // `Want proof? 92% of '${props.targetAudience}' see positive results within the first 2 weeks of using '${props.brandNameNiche}'. It’s all because of our '${props.keyBrandValuesUSP}'.`,
              // `Looking for the best? '${props.brandNameNiche}' has helped over 40,000 '${props.targetAudience}' find success, thanks to our '${props.keyBrandValuesUSP}'.`,
              // `The stats speak for themselves: '${props.targetAudience}' who use '${props.brandNameNiche}' experience a 65% improvement in satisfaction, thanks to our '${props.keyBrandValuesUSP}'.`,
              `Share an interesting statistic about how the '${props.brandNameNiche}' industry has evolved over the past year. What does this mean for '${props.targetAudience}'?`,
              `Highlight a report showcasing the growth rate in '${props.brandNameNiche}' and explain how '${props.targetAudience}' can benefit from these trends.`,
              `Provide data on the top challenges faced by '${props.targetAudience}' within the '${props.brandNameNiche}' industry and what solutions are emerging.`,
              `Discuss how a key global event has impacted the '${props.brandNameNiche}' industry. Use data to back up the changes and offer insights to '${props.targetAudience}'.`,
              `Reveal a recent survey about customer preferences in '${props.brandNameNiche}'. What does this mean for the future of the industry?`,
              `Provide a comparison of market sizes between two regions or countries in the '${props.brandNameNiche}' industry and analyze what this means for '${props.targetAudience}'.`,
              `Highlight key financial figures about the most successful companies in '${props.brandNameNiche}'. What can '${props.targetAudience}' learn from these industry leaders?`,
              `Share statistics on how the adoption of new technology is transforming '${props.brandNameNiche}'. How can '${props.targetAudience}' leverage this shift?`,
              `Offer insights on how environmental or regulatory changes are impacting the '${props.brandNameNiche}' sector. What do the numbers say about the future?`,
              `Analyze the growth trends of a sub-sector within '${props.brandNameNiche}' and explain why this matters for '${props.targetAudience}'.`,
              `Present a statistic showing how fast '${props.brandNameNiche}' is growing compared to other industries. Why is now the time to get involved?`,
              `Share global data showing differences in '${props.brandNameNiche}' trends across various regions. What can '${props.targetAudience}' learn from these differences?`,
              `Highlight a stat about the average spend within '${props.brandNameNiche}' and what '${props.targetAudience}' should know to stay competitive.`,
              `Use a graph or data visualization to show '${props.targetAudience}' the major players in '${props.brandNameNiche}' and what they’re doing differently.`,
              `Showcase research that points to emerging consumer habits in '${props.brandNameNiche}' and how '${props.targetAudience}' can adapt.`,
              `Offer a compelling fact about the future projection of '${props.brandNameNiche}' growth and why '${props.targetAudience}' should be aware.`,
              `Reveal a data point related to job growth in '${props.brandNameNiche}'. How does this affect the industry’s outlook?`,
              `Provide a figure showing how digital transformation is driving change within '${props.brandNameNiche}', helping '${props.targetAudience}' keep pace.`,
              `Present a case study with industry-wide statistics that exemplify best practices in '${props.brandNameNiche}'.`,
              `Summarize a recent report or whitepaper that reveals important figures related to sustainability in '${props.brandNameNiche}'. How does this affect future business decisions?`,
            ],
            image: `${Stats}`,
        },
        tile4: {
            color:"#C03EC5",
            heading: "Viral Headlines",
            content: "Craft attention-grabbing viral headlines effortlessly.",
            button: "Get My Headline",
            prompt: `Create 5 viral headline ideas that are attention-grabbing and designed to engage ${props.targetAudience} on ${props.socialMediaPlatforms}. The headlines should reflect ${props.keyBrandValuesUSP} and follow the tone ${props.toneVoiceContent}.
            Do a mix of russell brunson style headlines, medium length headlines, and short length headlines 
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
                    // 1. "How to Transform Your ${props.targetAudience} Strategy in Just 30 Days with ${props.brandNameNiche}!"
                    // 2. "The #1 Secret to Doubling Your ${props.targetAudience}'s Success in [Niche]!"
                    // 3. "Unlock the Hidden Power of ${props.brandNameNiche} for Unstoppable Growth!"
                    // 4. "You Won't Believe What Happened When ${props.targetAudience} Tried ${props.brandNameNiche}!"
                    // 5. "5 Mind-Blowing Ways ${props.brandNameNiche} Can Revolutionize ${props.targetAudience}'s Business!"
                    // 6. "Warning: Are You Making These Costly Mistakes in ${props.mainTeachingTopics}?"
                    // 7. "The Ultimate Guide to ${props.mainTeachingTopics} — Learn from the Best in ${props.brandNameNiche}!"
                    // 8. "Why ${props.targetAudience} Can’t Afford to Ignore ${props.brandNameNiche} Any Longer!"
                    // 9. "Discover the Exact Strategy That Helped ${props.targetAudience} 10x Their Results with ${props.brandNameNiche}!"
                    // 10. "Unlock the Full Potential of ${props.targetAudience} with These Insider Tips from ${props.brandNameNiche}!"`+promptPostStructure,
            prompts: [
              `How to Transform Your '${props.targetAudience}' Strategy in Just 30 Days with '${props.brandNameNiche}'!`,
              `The #1 Secret to Doubling Your '${props.targetAudience}''s Success in [Niche]!`,
              `Unlock the Hidden Power of '${props.brandNameNiche}' for Unstoppable Growth!`,
              `You Won't Believe What Happened When '${props.targetAudience}' Tried '${props.brandNameNiche}'!`,
              `5 Mind-Blowing Ways '${props.brandNameNiche}' Can Revolutionize '${props.targetAudience}''s Business!`,
              `Warning: Are You Making These Costly Mistakes in '${props.mainTeachingTopics}'?`,
              `The Ultimate Guide to '${props.mainTeachingTopics}' — Learn from the Best in '${props.brandNameNiche}'!`,
              `Why '${props.targetAudience}' Can’t Afford to Ignore '${props.brandNameNiche}' Any Longer!`,
              `Discover the Exact Strategy That Helped '${props.targetAudience}' 10x Their Results with '${props.brandNameNiche}'!`,
              `Unlock the Full Potential of '${props.targetAudience}' with These Insider Tips from '${props.brandNameNiche}'!`,
              `Why '${props.targetAudience}' Are Raving About '${props.brandNameNiche}''s Game-Changing '${props.keyBrandValuesUSP}'!`,
              `This Is Why '${props.targetAudience}' Can't Get Enough of '${props.brandNameNiche}'!`,
              `You Won’t Believe the Results '${props.targetAudience}' Are Seeing with '${props.brandNameNiche}'!`,
              `Discover the Secret Behind '${props.brandNameNiche}''s Success—'${props.targetAudience}' Are Loving It!`,
              `The Revolutionary '${props.keyBrandValuesUSP}' That’s Changing the Game for '${props.targetAudience}'!`,
              `What Makes '${props.brandNameNiche}' the Best Choice for '${props.targetAudience}'? Here’s the Answer.`,
              `Join the Thousands of '${props.targetAudience}' Who Are Transforming Their Lives with '${props.brandNameNiche}'!`,
              `This Is What Sets '${props.brandNameNiche}' Apart—Find Out Why '${props.targetAudience}' Trust Us!`,
              `The Key to '${props.targetAudience}' Success? It’s '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}'!`,
              `Discover the One Thing '${props.targetAudience}' Can’t Live Without—'${props.brandNameNiche}'!`,
              `Want to Know Why '${props.targetAudience}' Are Switching to '${props.brandNameNiche}'? Here’s What You Need to Know!`,
              `The Must-Know Reason '${props.targetAudience}' Are Choosing '${props.brandNameNiche}'!`,
              `Find Out How '${props.brandNameNiche}' Is Solving '${props.targetAudience}''s Biggest Problems.`,
              `The Secret Behind '${props.brandNameNiche}''s Massive Success with '${props.targetAudience}'.`,
              `This Little-Known '${props.keyBrandValuesUSP}' Is Changing the Lives of '${props.targetAudience}' Everywhere!`,
              `What Makes '${props.brandNameNiche}' a Favorite Among '${props.targetAudience}'? The Results Speak for Themselves!`,
              `Join the Movement: Why '${props.targetAudience}' Everywhere Are Choosing '${props.brandNameNiche}'.`,
              `Discover the Surprising Reason '${props.targetAudience}' Are Turning to '${props.brandNameNiche}'.`,
              `Ready to Transform Your Life? Here’s How '${props.brandNameNiche}' Is Helping '${props.targetAudience}' Do Just That.`,
              `The Simple Solution That’s Making a Big Difference for '${props.targetAudience}': '${props.brandNameNiche}'.`,
            ],
            image: `${HeadlinesIMG}`,
        },
        tile5: {
            color:"#FF363A",
            heading: "Quotes/Motivation",
            content: "Inspire your audience with motivational quotes.",
            button: "Find My Quote",
            prompt: `Create 5 diverse quotation posts that reflect ${props.keyBrandValuesUSP} and appeal to ${props.targetAudience}. The posts should include impactful messages or quotes that resonate with your audience’s challenges and aspirations. Occasionally, pull from ${props.topPerformingPosts} for inspiration where successful quotes can be reused effectively.
            Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.
            Provide in sets of 3 with a mix of long and medium length posts. `,
                    // 1. Inspirational Quote Post: Share a motivational quote that aligns with your brand values (${props.keyBrandValuesUSP}).
                    // 2. Educational Quote Post: Provide a teaching or tip in the form of a short quote related to ${props.mainTeachingTopics}.
                    // 3. Testimonial Quote: Highlight a customer's words as a testimonial, showcasing how your brand has made a difference.
                    // 4. Success Story Quote: Provide a quote from one of your top-performing success stories to inspire your audience.
                    // 5. Industry Insight Quote: Offer a quote or thought-leadership statement related to your brand’s niche (${props.brandNameNiche}).
                    // 6. Motivational Quote: Share an inspiring message that motivates your audience to take action, reflecting the tone (${props.toneVoiceContent}).
                    // 7. Brand Promise Quote: Share a quote that summarizes your brand’s mission or promise, tying it back to ${props.keyBrandValuesUSP}.
                    // 8. Holiday/Event Quote: Share a quote or message that’s tied to an upcoming holiday or event.
                    // 9. Educational Statistic Quote: Offer a statistic or fact, followed by a quote that connects to ${props.mainTeachingTopics} or niche.
                    // 10. Call to Action Quote: End with a post that offers a strong, action-based quote, encouraging your audience to engage with the brand or product.
                    // Ensure each post fits the ${props.toneVoiceContent} tone and is formatted for ${props.socialMediaPlatforms}.`+promptPostStructure,
            prompts: [
              `Success starts with the right choice—'${props.brandNameNiche}'. Let our '${props.keyBrandValuesUSP}' guide your journey!`,
              `Empower yourself with '${props.brandNameNiche}'—because you deserve the best solution, '${props.targetAudience}'!`,
              `The only limit is your commitment. Let '${props.brandNameNiche}' and our '${props.keyBrandValuesUSP}' help you break through!`,
              `Dream big, work hard, and trust '${props.brandNameNiche}' to take care of the rest.`,
              `The future belongs to those who believe in '${props.brandNameNiche}'. Our '${props.keyBrandValuesUSP}' is your key to success.`,
              `Start where you are. Use what you have. Trust '${props.brandNameNiche}' to get you where you want to be.`,
              `Every great journey begins with the right decision—choose '${props.brandNameNiche}'!`,
              `Consistency, hard work, and '${props.brandNameNiche}'—your formula for success.`,
              `With '${props.brandNameNiche}' by your side, success is no longer a dream but a reality!`,
              `Achieve your goals with confidence—'${props.brandNameNiche}' is here to help every step of the way.`,
              `Success is not just a destination, it’s a journey with '${props.brandNameNiche}' by your side.`,
              `Let your journey to success begin with the right partner—'${props.brandNameNiche}'.`,
              `Great things come to those who believe in themselves and choose '${props.brandNameNiche}'.`,
              `Dream big, start small, and let '${props.brandNameNiche}' help you reach your full potential.`,
              `Success is built on strong foundations—trust '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' to pave the way.`,
              `Your success story starts with one decision—choosing '${props.brandNameNiche}'.`,
              `Believe in yourself, trust '${props.brandNameNiche}', and watch your dreams come to life.`,
              `In every challenge, there’s an opportunity—let '${props.brandNameNiche}' help you seize it.`,
              `Start strong, stay committed, and let '${props.brandNameNiche}' fuel your success.`,
              `Success is not just about hard work, but also about having the right support—trust '${props.brandNameNiche}'.`,
            ],
            image: `${QuotesImg}`,
        },
        tile6: {
            color:"#FF3AB0",
            heading: "Myth Buster",
            content: "Debunk common myths with simple facts.",
            button: "Bust My Myth",
            prompt: `Create 5 myth-busting posts for ${props.brandNameNiche} that will help clarify misconceptions for ${props.targetAudience}. These should be posted on ${props.socialMediaPlatforms} and reflect the brand’s tone (${props.toneVoiceContent}) and values (${props.keyBrandValuesUSP}).
            Debunk common myths with simple facts. Delivered in sets of 5 until meeting the requested quantity.
            Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
                    // 1. Myth: "You Need a Huge Budget to Be Successful in ${props.mainTeachingTopics}"
                    //   Fact: "With the right strategy, anyone can succeed on a reasonable budget!"
                    // 2. Myth: "Only Big Brands Can Benefit from ${props.mainTeachingTopics}"
                    //   Fact: "${props.brandNameNiche} helps businesses of all sizes thrive!"
                    // 3. Myth: "You Need to Be an Expert to Master ${props.mainTeachingTopics}"
                    //   Fact: "Even beginners can see huge success with ${props.brandNameNiche}'s guidance."
                    // 4. Myth: "It Takes Years to See Results in ${props.mainTeachingTopics}"
                    //   Fact: "With ${props.brandNameNiche}, results come quicker than you think!"
                    // 5. Myth: "${props.mainTeachingTopics} is Too Complicated for Small Business Owners"
                    //   Fact: "${props.brandNameNiche} makes it simple and approachable for everyone."
                    // 6. Myth: "Success in ${props.mainTeachingTopics} is Pure Luck"
                    //   Fact: "Success is about strategy and consistency, not luck."
                    // 7. Myth: "You Need to Be Constantly Online to Succeed in ${props.socialMediaPlatforms}"
                    //   Fact: "With the right tools, you can grow without being online 24/7!"
                    // 8. Myth: "Content Creation is Only for Creatives"
                    //   Fact: "With the help of ${props.brandNameNiche}, anyone can create compelling content."
                    // 9. Myth: "You Have to Follow Trends to Succeed"
                    //   Fact: "Sticking to your brand values (${props.keyBrandValuesUSP}) is more important!"
                    // 10. Myth: "Paid Ads Are the Only Way to Grow"
                    //   Fact: "Organic growth through quality content and engagement can be just as powerful."`+promptPostStructure,
            prompts: [
              `Myth: All brands are the same. Fact: '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' sets us apart!`,
              `Myth: You need to spend a fortune to get quality results. Fact: '${props.brandNameNiche}' proves that’s not true!`,
              `Myth: It’s too late to start something new. Fact: With '${props.brandNameNiche}', it’s never too late to succeed.`,
              `Myth: Only experts can see real results. Fact: '${props.brandNameNiche}' is designed to help '${props.targetAudience}' at any level!`,
              `Myth: High quality always means high cost. Fact: '${props.brandNameNiche}' offers top-tier value without breaking the bank.`,
              `Myth: You need to be a pro to use '${props.brandNameNiche}'. Fact: We make it easy for everyone!`,
              `Myth: All solutions are the same. Fact: '${props.brandNameNiche}''s unique '${props.keyBrandValuesUSP}' makes all the difference.`,
              `Myth: You can’t achieve results quickly. Fact: '${props.targetAudience}' see results fast with '${props.brandNameNiche}'.`,
              `Myth: You have to figure it all out on your own. Fact: '${props.brandNameNiche}' provides all the support you need.`,
              `Myth: Success is out of reach. Fact: '${props.brandNameNiche}' makes success achievable for everyone!`,
              `Myth: You can’t trust new brands. Fact: '${props.brandNameNiche}' has earned the trust of '${props.targetAudience}' everywhere.`,
              `Myth: You need expensive tools for real success. Fact: '${props.brandNameNiche}' offers everything you need without the high cost.`,
              `Myth: It takes forever to see results. Fact: '${props.targetAudience}' see rapid improvement with '${props.brandNameNiche}'.`,
              `Myth: Only professionals can benefit from '${props.brandNameNiche}'. Fact: We cater to all '${props.targetAudience}'!`,
              `Myth: Quality service comes with a high price. Fact: '${props.brandNameNiche}' gives you the best without the big price tag.`,
              `Myth: It’s impossible to stand out. Fact: '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' ensures you do.`,
              `Myth: All products are created equal. Fact: '${props.brandNameNiche}' offers unique value through our '${props.keyBrandValuesUSP}'.`,
              `Myth: There’s no such thing as affordable quality. Fact: '${props.brandNameNiche}' proves it’s possible!`,
              `Myth: Only experienced people can benefit from '${props.brandNameNiche}'. Fact: We’re here for all levels of '${props.targetAudience}'.`,
              `Myth: You need a large budget to succeed. Fact: '${props.brandNameNiche}' helps '${props.targetAudience}' thrive without breaking the bank.`,
            ],
            image: `${MythBusterImg}`,
        },
        tile7: {
            color:"#30D8B7",
            heading: "Facts/Education",
            content: "Deliver educational facts and insightful content.",
            button: "Share My Fact",
            prompt: `Create 5 educational posts focused on delivering key facts or insights about ${props.brandNameNiche}, designed to inform ${props.targetAudience} on ${props.socialMediaPlatforms}. These posts should reflect ${props.keyBrandValuesUSP} and follow the tone ${props.toneVoiceContent}.
            Create 3 facebook posts each  including an image caption stating the statistic or fact for enhanced credibility.
            Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
                    // 1. Did You Know?: Share a little-known fact about ${props.mainTeachingTopics} that will surprise your audience.
                    // 2. Industry History: Educate ${props.targetAudience} about the history of ${props.brandNameNiche} and how it’s evolved over time.
                    // 3. Common Mistakes: List 5 common mistakes ${props.targetAudience} makes in ${props.mainTeachingTopics}, with tips on how to avoid them.
                    // 4. Step-by-Step Process: Provide a simplified step-by-step guide on how to implement one of ${props.mainTeachingTopics}.
                    // 5. Essential Tools: Share 3-5 must-have tools that ${props.targetAudience} can use to succeed in ${props.mainTeachingTopics}.
                    // 6. How It Works: Educate your audience on the mechanics behind your service/product, breaking down the key processes.
                    // 7. Fact vs. Fiction: Compare a popular myth vs. the reality in your industry, providing clarity for ${props.targetAudience}.
                    // 8. Why It Matters: Explain why one of ${props.mainTeachingTopics} is so important for ${props.targetAudience} and how it can help solve their pain points.
                    // 9. Quick Tips: Offer 3-5 quick tips to help ${props.targetAudience} master ${props.mainTeachingTopics} faster.
                    // 10. Success Formula: Share the formula for success in ${props.mainTeachingTopics}, based on ${props.keyBrandValuesUSP}.`+promptPostStructure,
            prompts: [
              // `Fact: '${props.brandNameNiche}' is designed to solve '${props.targetAudience}''s specific challenges with our unique '${props.keyBrandValuesUSP}'.`,
              // `Did you know? '${props.brandNameNiche}' incorporates cutting-edge solutions to help '${props.targetAudience}' achieve their goals faster.`,
              // `Education time! Here's why '${props.keyBrandValuesUSP}' is a game changer for '${props.targetAudience}' when they choose '${props.brandNameNiche}'.`,
              // `Fun Fact: Over 75% of '${props.targetAudience}' report improved outcomes within just weeks of using '${props.brandNameNiche}'.`,
              // `Here's something you didn’t know: '${props.brandNameNiche}' focuses on '${props.keyBrandValuesUSP}', designed specifically for '${props.targetAudience}'.`,
              // `Want to learn more? '${props.brandNameNiche}''s approach to '${props.keyBrandValuesUSP}' sets us apart in the industry.`,
              // `Fact: '${props.brandNameNiche}' helps '${props.targetAudience}' overcome their biggest frustrations by focusing on '${props.keyBrandValuesUSP}'.`,
              // `Did you know? '${props.targetAudience}' can achieve results faster with '${props.brandNameNiche}' thanks to our innovative '${props.keyBrandValuesUSP}'.`,
              // `Learning moment: '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' is crafted to support '${props.targetAudience}''s specific needs.`,
              // `Fun Fact: Our '${props.keyBrandValuesUSP}' has helped thousands of '${props.targetAudience}' reach their goals faster and more effectively.`,
              // `Fact: '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' is proven to increase success rates among '${props.targetAudience}' by 30%.`,
              // `Did you know? '${props.brandNameNiche}' was created with '${props.targetAudience}''s specific frustrations in mind to deliver real results.`,
              // `Here’s a quick educational tip: Choosing '${props.brandNameNiche}' means access to our '${props.keyBrandValuesUSP}', which ensures you’re set up for success.`,
              // `Fact: '${props.brandNameNiche}' has been shown to improve satisfaction rates by over 50% among '${props.targetAudience}'.`,
              // `Want to learn more about '${props.brandNameNiche}'? Our '${props.keyBrandValuesUSP}' makes all the difference when it comes to '${props.targetAudience}''s goals.`,
              // `Education time! '${props.brandNameNiche}' is built on a foundation of '${props.keyBrandValuesUSP}', helping '${props.targetAudience}' thrive.`,
              // `Fun Fact: '${props.brandNameNiche}''s innovative approach to '${props.keyBrandValuesUSP}' is what makes us the best choice for '${props.targetAudience}'.`,
              // `Learning moment: '${props.targetAudience}' benefit from '${props.brandNameNiche}' because of our unique focus on '${props.keyBrandValuesUSP}'.`,
              // `Here’s a fact: '${props.brandNameNiche}' has helped over 100,000 '${props.targetAudience}' by delivering on our promise of '${props.keyBrandValuesUSP}'.`,
              // `Want to know the secret behind '${props.brandNameNiche}'? Our commitment to '${props.keyBrandValuesUSP}' is what drives success for '${props.targetAudience}'.`,
              `Explain the history of '${props.brandNameNiche}' and its evolution over the past few decades. What should '${props.targetAudience}' know about its roots?`,
              `Provide an overview of the top regulatory changes affecting the '${props.brandNameNiche}' industry and what '${props.targetAudience}' needs to comply with.`,
              `Share a little-known fact about the science or technology behind '${props.brandNameNiche}' and how it impacts the industry.`,
              `Educate your audience on the most important certifications or qualifications needed to succeed in '${props.brandNameNiche}'.`,
              `Describe a key innovation in '${props.brandNameNiche}' that transformed the industry. Why is this important for '${props.targetAudience}' to understand?`,
              `Explain common misconceptions about '${props.brandNameNiche}' and clarify how the industry actually works.`,
              `Teach your audience about the different roles or careers within '${props.brandNameNiche}' and what skills are needed for success.`,
              `Share educational content about the environmental or sustainability efforts within '${props.brandNameNiche}'.`,
              `Provide a fact on how globalization has influenced '${props.brandNameNiche}', and why it’s important for '${props.targetAudience}'.`,
              `Explain how emerging technologies like AI, blockchain, or automation are changing '${props.brandNameNiche}' and why it matters for the future.`,
              `Break down the current industry trends in '${props.brandNameNiche}' for your audience. What are the top developments?`,
              `Share facts about the materials or processes used in '${props.brandNameNiche}' and why they matter to '${props.targetAudience}'.`,
              `Explain the different market segments in '${props.brandNameNiche}' and which one is most relevant to '${props.targetAudience}'.`,
              `Teach your audience about the global supply chain dynamics that influence '${props.brandNameNiche}'. What should they know?`,
              `Share a historical milestone that changed the course of '${props.brandNameNiche}' forever.`,
              `Explain the importance of ethical practices within '${props.brandNameNiche}'. Why is this a growing focus in the industry?`,
              `Discuss the impact of technological disruption on '${props.brandNameNiche}', helping '${props.targetAudience}' understand the shifting landscape.`,
              `Provide a deep dive into the customer journey in '${props.brandNameNiche}' and how companies are adapting to serve better.`,
              `Share an industry fact related to consumer behavior in '${props.brandNameNiche}'. How are preferences changing?`,
              `Educate your audience on how global economic trends influence '${props.brandNameNiche}'. What economic indicators should they follow?`,
            ],
            image: `${FactsImg}`,
        },
        tile8: {
            color:"#960507",
            heading: "Promotional",
            content: "Create impactful promotional content for your brand.",
            button: "Create My Promo",
            prompt: `Create 5 promotional posts that effectively advertise ${props.brandNameNiche} on ${props.socialMediaPlatforms}, appealing directly to ${props.targetAudience}. The promotions should highlight ${props.keyBrandValuesUSP}, following the tone ${props.toneVoiceContent}.
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.
            **Authority Stats/Facts**: Provided in sets of 5 until the total of 12 is met, with each image caption stating the statistic or fact for enhanced credibility.
            Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.`,
                    // 1. Exclusive Offer: Announce a limited-time offer, encouraging ${props.targetAudience} to take action now to benefit from ${props.brandNameNiche}.
                    // 2. Discount Announcement: Promote a special discount for a specific product or service, explaining how it solves a key frustration for ${props.targetAudience}.
                    // 3. New Product Launch: Excite your audience about the launch of a new product or service, focusing on how it will benefit them.
                    // 4. Free Trial or Demo: Offer a free trial or demo of one of your products/services to give ${props.targetAudience} a risk-free way to experience ${props.brandNameNiche}.
                    // 5. Referral Program: Promote your referral program, highlighting how ${props.targetAudience} can benefit from sharing your brand with others.
                    // 6. Flash Sale: Create urgency around a flash sale for your top-selling product, encouraging immediate action.
                    // 7. Bundle Deal: Promote a bundle offer where multiple products/services are available at a discounted rate for a limited time.
                    // 8. Seasonal Offer: Run a seasonal promotion tied to an upcoming holiday or event, encouraging your audience to take advantage before the season ends.
                    // 9. Loyalty Rewards: Announce a loyalty program or rewards system for your most dedicated customers, motivating repeat business.
                    // 10. Early Access: Offer early access to a product or service for loyal customers, framing it as an exclusive perk for your audience.`+promptPostStructure,
            prompts: [
              `Exclusive Offer: Announce a limited-time offer, encouraging ${props.targetAudience} to take action now to benefit from ${props.brandNameNiche}.`,
              `Discount Announcement: Promote a special discount for a specific product or service, explaining how it solves a key frustration for ${props.targetAudience}.`,
              `New Product Launch: Excite your audience about the launch of a new product or service, focusing on how it will benefit them.`,
              `Free Trial or Demo: Offer a free trial or demo of one of your products/services to give ${props.targetAudience} a risk-free way to experience ${props.brandNameNiche}.`,
              `Referral Program: Promote your referral program, highlighting how ${props.targetAudience} can benefit from sharing your brand with others.`,
              `Flash Sale: Create urgency around a flash sale for your top-selling product, encouraging immediate action.`,
              `Bundle Deal: Promote a bundle offer where multiple products/services are available at a discounted rate for a limited time.`,
              `Seasonal Offer: Run a seasonal promotion tied to an upcoming holiday or event, encouraging your audience to take advantage before the season ends.`,
              `Loyalty Rewards: Announce a loyalty program or rewards system for your most dedicated customers, motivating repeat business.`,
              `Early Access: Offer early access to a product or service for loyal customers, framing it as an exclusive perk for your audience.`,
              `VIP Membership Invitation: 'Join the club! ${props.brandNameNiche} is offering exclusive access to our VIP membership program, giving ${props.targetAudience} early access to new products and members-only discounts.'`,
              `Buy One, Get One Free: 'Double the value! For a limited time, buy one product from ${props.brandNameNiche} and get a second one for free. Perfect for ${props.targetAudience} who need more solutions for ${props.keyBrandValuesUSP}!'`,
              `Free Gift with Purchase: 'Treat yourself twice! Purchase any product from ${props.brandNameNiche} and receive a free gift—ideal for ${props.targetAudience} looking for more from their shopping experience.'`,
              `Exclusive Webinar Access: 'Learn from the best! Sign up now and get free access to ${props.brandNameNiche}'s exclusive webinar, designed specifically for ${props.targetAudience} who want to master ${props.keyBrandValuesUSP}.'`,
              `Personalized Consultation: 'We’re here for you! ${props.brandNameNiche} is offering personalized one-on-one consultations for ${props.targetAudience} to help them find the best solution for ${props.keyBrandValuesUSP}. Book your slot today!'`,
              `Limited Edition Product: 'Don’t miss out! ${props.brandNameNiche} is launching a limited edition product, exclusively available to our ${props.targetAudience}. Grab yours before it’s gone!'`,
              `Mystery Box Offer: 'Love surprises? Purchase from ${props.brandNameNiche} today and get a mystery box filled with top products perfect for ${props.targetAudience}. You never know what amazing items you’ll find!'`,
              `Giveaway Contest: 'Win big! ${props.brandNameNiche} is running a giveaway contest where ${props.targetAudience} can win our top products just by entering. Don’t miss your chance to win!'`,
              `Exclusive Partner Discounts: 'Get more for less! We’ve partnered with other brands to bring ${props.targetAudience} exclusive discounts. Shop now and save on more products that complement ${props.keyBrandValuesUSP}.'`,
              `Early Bird Pricing: 'The early bird gets the best deal! Pre-order now from ${props.brandNameNiche} and enjoy early bird pricing on our newest product, specially designed for ${props.targetAudience}.'`,
              `Limited-Time Bundle with Free Course: 'Get more than you expect! Purchase from ${props.brandNameNiche} and receive access to an exclusive free course tailored for ${props.targetAudience} on mastering ${props.keyBrandValuesUSP}. Limited time only!'`,
              `Customized Product Offer: 'Your product, your way! ${props.brandNameNiche} is offering customized product options tailored specifically for ${props.targetAudience}. Create something unique to solve ${props.keyBrandValuesUSP}.'`,
              `Refer-a-Friend with Exclusive Bonus: 'Double the rewards! Refer a friend to ${props.brandNameNiche} and both of you will receive an exclusive bonus, designed to help ${props.targetAudience} and their network experience ${props.keyBrandValuesUSP}.'`,
              `Trade-In Program: 'Upgrade with ease! Trade in your old product and receive a discount on a brand new item from ${props.brandNameNiche}, perfect for ${props.targetAudience} wanting to enhance their experience with ${props.keyBrandValuesUSP}.'`,
              `Customer Appreciation Week: 'Thank you for being amazing! ${props.brandNameNiche} is celebrating ${props.targetAudience} with special offers and discounts all week long to show our appreciation for trusting us with ${props.keyBrandValuesUSP}.'`,
              `Flash Giveaway Event: 'Surprise giveaway! Follow ${props.brandNameNiche} for a chance to win one of our top products during our flash giveaway event—ideal for ${props.targetAudience} looking to get more from ${props.keyBrandValuesUSP}.'`,
              `Charity Donation Match: 'Shop and support! For every product purchased, ${props.brandNameNiche} will match your contribution to a charity that aligns with ${props.targetAudience}'s values—so you can do good while solving ${props.keyBrandValuesUSP}.'`,
              `Free Upgrade Offer: 'Upgrade your experience! ${props.brandNameNiche} is offering a free upgrade on select products or services for ${props.targetAudience}, helping you get more for less and experience our ${props.keyBrandValuesUSP}.'`,
              `Pop-Up Shop Event: 'Catch us live! ${props.brandNameNiche} is hosting a pop-up shop event exclusively for ${props.targetAudience}. Enjoy in-person discounts and offers you won’t find online!'`,
              `Surprise Discount for Loyal Customers: 'We love surprises, and we know you do too! Loyal ${props.targetAudience} of ${props.brandNameNiche} will receive an unexpected discount on their next purchase. Stay tuned for a little extra love!'`,
            ],
            image: `${PromotionalImg}`,
        },
        tile9: {
            color:"#C5913E",
            heading: "Case Study",
            content: "Showcase success stories and testimonials easily.",
            button: "Write My Case Study",
            prompt: `Create 5 case study posts that showcase the success of ${props.brandNameNiche}, focusing on results you've achieved for ${props.targetAudience}. These posts should align with ${props.keyBrandValuesUSP} and be shared on ${props.socialMediaPlatforms}, using ${props.toneVoiceContent}.
            **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.
            Each post will be crafted to maximize variety in length and style, with 3 posts, including 1 long rich in detail and 1 medium-length with unique messaging styles. posts avoid embedded links and instead clearly display URLs for social platforms.`,
                    // 1. Customer Success Story: Share a detailed story of how one of your clients solved a major problem with the help of ${props.brandNameNiche}.
                    // 2. Before & After Case Study: Highlight a transformation where you helped a client improve from a struggling state to a successful outcome.
                    // 3. Revenue Growth Example: Showcase a case study where a customer’s revenue increased significantly after implementing ${props.mainTeachingTopics}.
                    // 4. Efficiency Case Study: Present an example of how your product/service saved a customer time, focusing on specific numbers or percentages.
                    // 5. Client Testimonials Case Study: Combine customer quotes with real-world data to demonstrate how ${props.brandNameNiche} impacted their business.
                    // 6. Cost Reduction Success: Highlight a case study where a client was able to cut costs or increase profitability with the help of your services.
                    // 7. Niche-Specific Case Study: Share a case study that relates directly to a niche within your target audience, making it more relatable.
                    // 8. Step-by-Step Breakdown: Walk your audience through a detailed breakdown of how you achieved results for a client in ${props.mainTeachingTopics}.
                    // 9. Long-Term Results Case Study: Focus on a client who has seen sustained, long-term benefits from working with ${props.brandNameNiche}.
                    // 10. Collaboration Success: Highlight a collaborative effort between your team and a client, showing how teamwork led to outstanding results.`+promptPostStructure,
            prompts: [
              `Meet Sarah, a '${props.targetAudience}' who transformed her journey with '${props.brandNameNiche}'. Our '${props.keyBrandValuesUSP}' made all the difference!`,
              `How '${props.brandNameNiche}' helped Tom, a '${props.targetAudience}', overcome his biggest challenge with our '${props.keyBrandValuesUSP}'. Here’s his story.`,
              `See how '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' helped Jane, a '${props.targetAudience}', achieve success in just 30 days!`,
              `Case Study: Learn how '${props.brandNameNiche}' empowered thousands of '${props.targetAudience}' to reach their goals with our unique '${props.keyBrandValuesUSP}'.`,
              `Want to know how '${props.brandNameNiche}' can help you? Here’s how we helped Lisa, a '${props.targetAudience}', succeed with our '${props.keyBrandValuesUSP}'.`,
              `Real results: Discover how '${props.targetAudience}' like Mark used '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' to overcome their biggest obstacles.`,
              `Case Study: John, a '${props.targetAudience}', achieved his goals in record time with '${props.brandNameNiche}'. Our '${props.keyBrandValuesUSP}' was the key!`,
              `How '${props.brandNameNiche}' helped Amanda, a '${props.targetAudience}', go from frustrated to fulfilled with our innovative '${props.keyBrandValuesUSP}'.`,
              `Success story: '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' transformed Mike's journey. Find out how it could work for you!`,
              `Meet Jessica, a '${props.targetAudience}', who found success with '${props.brandNameNiche}'. Our '${props.keyBrandValuesUSP}' was the game changer.`,
              `How '${props.brandNameNiche}' helped Alex, a '${props.targetAudience}', overcome frustrations with our '${props.keyBrandValuesUSP}'. His results are inspiring!`,
              `Discover how Maria, a '${props.targetAudience}', used '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' to unlock new levels of success in just weeks!`,
              `Case Study: '${props.targetAudience}' like David are achieving more with '${props.brandNameNiche}'. Learn how our '${props.keyBrandValuesUSP}' made the difference.`,
              `Real people, real results. '${props.brandNameNiche}' helped Sarah, a '${props.targetAudience}', achieve her dreams with our '${props.keyBrandValuesUSP}'.`,
              `Here’s how '${props.brandNameNiche}' turned Mike’s struggles into success with our proven '${props.keyBrandValuesUSP}'.`,
              `Meet Laura, a '${props.targetAudience}', who found her path to success with '${props.brandNameNiche}'. Our '${props.keyBrandValuesUSP}' was the key.`,
              `Success story: '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' transformed Jason's experience. Find out how it could work for you!`,
              `Case Study: See how '${props.brandNameNiche}' empowered Christina, a '${props.targetAudience}', to thrive with our innovative '${props.keyBrandValuesUSP}'.`,
              `How '${props.brandNameNiche}''s '${props.keyBrandValuesUSP}' helped George, a '${props.targetAudience}', overcome his biggest challenge and succeed!`,
              `Want results like Emily’s? Learn how '${props.brandNameNiche}' helped her achieve her goals with our powerful '${props.keyBrandValuesUSP}'.`,
            ],
            image: `${CaseStudyImg}`,
        },
        tile10: {
          color:"#960507",
          heading: "Product Promotion",
          content: "Create impactful promotional content for your product.",
          button: "Create Promotional Content",
          prompt: `${props.keyFeatures} ${props.productName} ${props.productURL} ${props.productDescription}`,
          prompts: "test",
          popup: true,
          image: `${PromotionalImg}`,
        }

    }
  return (
    <div className='flex gap-5 flex-wrap justify-center'>
        {
            Object.keys(content).map((key, index) => {
              const tile = content[key];
              return (
                <Tile
                  key={index}
                  color={tile.color}
                  heading={tile.heading}
                  content={tile.content}
                  button={tile.button}
                  prompt={tile.prompt}
                  popup={tile.popup}
                  prompts={tile.prompts}
                  image={tile.image}
                />
              );
            })
        }
    </div>
  )
}

export default Tiles
