import Content from './pages/Content.js';
import Home from './pages/Home.js';
import Results from './pages/Results.js';
import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
export default function HomeApp() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path="/content" element={<Content />} />
          <Route path='/results' element={<Results/>} />
        </Routes>
      </Router>
    </>
  );
}
