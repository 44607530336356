import { useEffect, useRef, useState } from 'react';
import { Formio } from 'formiojs';
import '../../styles/BrandForm.css';
import schema from '../form/schema.json';
import { useNavigate } from 'react-router-dom';

const LocalBrandForm = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const [submissionData, setSubmissionData] = useState(null);

  // Load saved form data from local storage when the form is loaded
  useEffect(() => {
    const formSchema = schema;

    // Create and render the form using the schema
    Formio.createForm(formRef.current, formSchema, {noAlerts: true}).then((form) => {
      // Load saved data from localStorage, if available
      const savedData = JSON.parse(localStorage.getItem('formData'));
      // console.log(savedData)
      if (savedData) {
        form.submission = { data: savedData }; // Pre-fill the form with saved data
      }

      // Save form data to localStorage on form submit
      form.on('submit', (submission) => {
        setSubmissionData(submission.data);
        // console.log('Form submitted:', submission);
        saveDataToLocalStorage(submission.data); // Save data
        navigate('/content'); // Navigate to another page after submission
      });

      // Optional: Save form data to localStorage on form change (autosave)
      form.on('change', (submission) => {
        saveDataToLocalStorage(submission.data); // Autosave form data
      });
    });
  }, []);

  // Function to save form data to localStorage
  const saveDataToLocalStorage = (formData) => {
    localStorage.setItem('formData', JSON.stringify(formData));
    // console.log('Form data saved to localStorage:', formData);
  };

  return (
    <>
      <div ref={formRef} className="space-y-4"></div>
    </>
  );
};

export default LocalBrandForm;
